<template>
  <div class="Upvoucher">
    <div class="top-title">
      <div class="top-text">上传凭证</div>
    </div>
    <div class="upvoucher-form">
      <el-form ref="form" :model="listpar" label-width="100px">
        <el-form-item label="钱数:">
          <el-input-number
            type="number"
            :min="0"
            v-model="listpar.money"
          ></el-input-number
          >&nbsp;元
        </el-form-item>
      </el-form>
    </div>

    <div class="top-box-image">
      <span>上传凭证</span>
      <el-upload
        class="avatar-uploader"
        :show-file-list="true"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
        :action="upLoadUrl"
        :data="headerMsg"
      >
        <img
          v-if="listpar.urlImage"
          :src="ImgUrl + listpar.urlImage"
          class="avatar"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>

    <div class="top-box-box">
      <el-button type="primary" class="biy-icon" @click="onSubmit()"
        >确认提交</el-button
      >
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js";
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js";
export default {
  data() {
    return {
      ImgUrl: config.ImgUrl,
      // 上传图片的地址
      upLoadUrl: config.uploadingimg,

      headerMsg: {
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        uploadfile: "file",
      },
      // 上传图片
      imageUrl: "",
      listpar: {
        urlImage: this.$route.query.urlImage,
        money: this.$route.query.money,
      },
      list: [],
    };
  },
  created() {
    this.$route.query.id == undefined ? "" : this.$route.query.id;
  },
  mounted() {},

  methods: {
    //确认弹窗
    onSubmit(e) {
  
      if ((this.listpar.money && this.listpar.urlImage) == undefined) {
       this.$alert("请上传凭证并输入金额", "消息提示", {
        confirmButtonText: "确定",
        callback: (action) => {},
      });
      } else {
        this.etype = e;
        this.$confirm("是否确认", "消息提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消操作",
        })
          .then(() => {
            this.onSubmits();
          })
          .catch((action) => {
            console.log("取消");
          });
      }
    },

    async onSubmits() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        bizCatalog: "AccountEntity",
        handleMode: "offlinePayin",
        todo: "upsertToAudit",
        bizData: {
          id: this.$route.query.id,
          money: this.listpar.money,
          offline_proof: this.listpar.urlImage,
        },
      });
 
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.$alert("操作成功", "消息提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$router.push({ path: `/home/voucherlist` });
        },
      });
    },

    // 图片上传
    handleAvatarSuccess(res, file) {
  
      this.imageUrl = URL.createObjectURL(file.raw);
      // 图片路径拼接
      this.listpar.urlImage = res.Result;
    },

    beforeAvatarUpload() {
      console.log(" ");
    },
  },
  computed: {},
};
</script>


<style  scoped>
.top-box-box {
  width: 200px;
  margin: 0 auto;
}
.top-box-box .biy-icon {
  width: 200px;
}
/* 上传 */
.top-box-image {
  width: 300px;
  display: flex;
  justify-content: space-around;
  height: 100px;
  font-size: 14px;
  overflow: hidden;
  margin: 0px auto;
}
.top-box-image span {
  line-height: 78px;
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  border: 1px solid rgba(153, 153, 153, 0.637);
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  height: 78px;
  display: block;
}

.upvoucher-form {
  padding-top: 100px;
  width: 300px;
  margin: 0 auto;
}
@import "../../assets/css/liststyle.css";
</style>